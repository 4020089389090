body {
  width: 100%;
}

shell-sc-notification-center {
  overflow: hidden;
  width: 360px;
}

// TODO: Remove This
.ag-theme-simcorp .ag-popup .ag-list {
  max-height: 500px !important;
}